import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {SelectModel} from "../../../../../../../app/types/SelectModel";
import {useAppSelector} from "../../../../../../../app/store";
import {selectCurrentUser} from "../../../../../../../features/authentication/authenticationSliceSelectors";
import {getUserRole} from "../../../../../../../helpers/user";
import {UserRole} from "../../../../../../../app/enums/UserRole";

type EditTransportTemperatureSelectorProps = {
    disabled: boolean;
    temperature: string | null;
    onChange: (ev: DropDownListChangeEvent) => void;
}

const EditTransportTemperatureSelector: React.FC<EditTransportTemperatureSelectorProps> = ({
                                                                                           temperature,
                                                                                           onChange,
                                                                                           disabled
                                                                                       }) => {
    const user = useAppSelector(selectCurrentUser);

    const data: Array<SelectModel> = [
        {
          text: "Ambient",
          value: "1"
        },
        {
          text: "7 - 15 °C",
          value: "2"
        },
        {
          text: "0 - 4 °C",
          value: "3"
        },
        {
          text: "- 25 °C",
          value: "4"
        }
      ];

    return (

        <DropDownList data={data}
                      className={'edit-transport-temperature-value'}
                      disabled={disabled || !user || getUserRole(user) === UserRole.ServiceProvider}
                      loading={!user}
                      textField="text"
                      dataItemKey="value"
                      value={temperature !== null ? data.find(e => e.value === temperature) : data.find(e => e.value === "1")}
                      onChange={onChange}
        />
    );
};

export default EditTransportTemperatureSelector;