import React from 'react';
import {YMInitializer} from "react-yandex-metrika";

const YandexMetrics: React.FC = () => {
    return (
        <div>
            <YMInitializer accounts={[97126541]}/>
        </div>
    );
};

export default YandexMetrics;
