import React from 'react';
import {useTranslation} from "react-i18next";
import cl from './ProfileHelp.module.css';
import {useAppSelector} from "../../../../app/store";
import {selectCurrentUser} from "../../../../features/authentication/authenticationSliceSelectors";
import {UserRole} from "../../../../app/enums/UserRole";
import ProfileHelpCard from "./ProfileHelpCard/ProfileHelpCard";
import {faFilePowerpoint, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {Player} from 'video-react';
import useDocumentTitle from "../../../../hooks/useDocumentTitle/useDocumentTitle";
import {useDocumentApi} from "../../../../app/api/document";
import {isAxiosError} from "axios";
import {ResponseResultCode} from "../../../../app/enums/ResponseResultCode";
import {toast} from "react-toastify";
import {IResponse, isResponse} from "../../../../app/interfaces/response/IResponse";
import {useDownloadFile} from "../../../../hooks/useDownloadFile/useDownloadFile";

const ProfileHelp = () => {
    const {t, i18n} = useTranslation();
    const user = useAppSelector(selectCurrentUser);

    useDocumentTitle({
        title: t("header.help")
    });

    const {
        getDocument: {
            query: getDocument
        }
    } = useDocumentApi();

    const {
        download
    } = useDownloadFile({
        target: '_blank',
        getBlob: async (param?: string) => {
            if (!param) {
                return;
            }

            try {
                const response = await getDocument(param);

                if (response?.status === 200 && !isResponse(response.data)) {
                    return response.data;
                }
            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.data && err.response.data.type === "application/json") {
                    const textResponse = await err.response?.data?.text();

                    if (textResponse && typeof textResponse === 'string') {
                        let response = JSON.parse(textResponse) as IResponse;

                        if (response.resultCode === ResponseResultCode.NotValidData) {
                            toast.error(`Unable to download file. Error: Input parameter is invalid.`)
                        }

                        if (response.resultCode === ResponseResultCode.NotFound) {
                            toast.error(`Unable to download file. Error: File with this name is not found.`)
                        }
                    }
                }
            }
        }
    });

    if (!user) {
        return null;
    }

    return (
        <div className={`w100 h100 hidden pd-5 ${cl.container}`}>
            <div className={`default-form-collapse-header prevent-selection ${cl.header}`}>
                {t("header.help")}
            </div>
            <div className={`${cl.content}`}>
                <div className={'pd-10'}>
                    <div className={cl.cards}>
                        {user.role !== UserRole.Client &&
                            <>
                                <ProfileHelpCard icon={faFilePdf}
                                                 title={t("profile.help.how-to-configure-receiving-notifications")}
                                                 onDownloadButtonClick={async () => {
                                                     let file = '';
                                                     switch (i18n.language.toLowerCase()) {
                                                         case "ru":
                                                             file = 'messengers_RU.pdf';
                                                             break;
                                                         case "pl":
                                                             file = 'messengers_PL.pdf';
                                                             break;
                                                         case "en":
                                                             file = 'messengers_EN.pdf';
                                                             break;
                                                     }

                                                     await download(file);
                                                 }}/>

                                <ProfileHelpCard icon={faFilePdf}
                                                 title={t("profile.help.download-the-presentation")}
                                                 onDownloadButtonClick={async () => {
                                                     let file = '';
                                                     switch (i18n.language.toLowerCase()) {
                                                         case "ru":
                                                             file = 'Презентация.pdf';
                                                             break;
                                                         case "pl":
                                                             file = 'Prezentacja.pdf';
                                                             break;
                                                         case "en":
                                                             file = 'Presentation.pdf';
                                                             break;
                                                     }

                                                     await download(file);
                                                 }}/>
                            </>
                        }

                        {user.role === UserRole.Forwarder || user.role === UserRole.Administrator
                            ? <>
                                <ProfileHelpCard icon={faFilePowerpoint}
                                                 title={t("profile.help.download-the-forwarder-guide")}
                                                 onDownloadButtonClick={async () => {
                                                     let file = '';
                                                     switch (i18n.language.toLowerCase()) {
                                                         case "ru":
                                                             file = 'Forwarder_Guide_RUS.pptx';
                                                             break;
                                                         case "pl":
                                                             file = 'Forwarder_Guide_POL.pptx';
                                                             break;
                                                         case "en":
                                                             file = 'Forwarder_Guide_ENG.pptx';
                                                             break;
                                                     }

                                                     await download(file);
                                                 }}/>
                            </>
                            : null
                        }

                        {user.role === UserRole.ServiceProvider &&
                            <ProfileHelpCard icon={faFilePdf}
                                             title={t("profile.help.download-the-user-manual")}
                                             onDownloadButtonClick={async () => {
                                                 let file = '';
                                                 switch (i18n.language.toLowerCase()) {
                                                     case "ru":
                                                         file = 'The_instruction_of_the_user_Suppliers_Cabinet_ru.pdf';
                                                         break;
                                                     case "pl":
                                                         file = 'The_instruction_of_the_user_Suppliers_Cabinet_pl.pdf';
                                                         break;
                                                     case "en":
                                                         file = 'The_instruction_of_the_user_Suppliers_Cabinet.pdf';
                                                         break;
                                                 }

                                                 await download(file);
                                             }}/>
                        }

                        {user.role === UserRole.Client &&
                            <ProfileHelpCard icon={faFilePowerpoint}
                                             title={t("profile.help.download-the-user-manual")}
                                             onDownloadButtonClick={async () => {
                                                 let file = '';
                                                 switch (i18n.language.toLowerCase()) {
                                                     case "ru":
                                                         file = 'Customer_Cab_ru.pdf';
                                                         break;
                                                     case "pl":
                                                         file = 'Customer_Cab_pl.pdf';
                                                         break;
                                                     case "en":
                                                         file = 'Customer_Cab_en.pdf';
                                                         break;
                                                 }

                                                 await download(file);
                                             }}/>
                        }
                    </div>
                </div>

                <div className={cl.separator}>

                </div>

                <div>
                    {user.role !== UserRole.Client &&
                        <div className={'pd-10'}>
                            {i18n.language.toLowerCase() === 'ru' &&
                                <Player preload={'metadata'}
                                        ref={rf => {
                                            if (rf) {
                                                rf.volume = 0.1;
                                            }
                                        }}>
                                    <source src="https://portal.asstra.com/video/AsstrA - Track & Trace RUS.mp4"/>
                                </Player>
                            }

                            {i18n.language.toLowerCase() === 'en' &&
                                <Player preload={'metadata'}
                                        ref={rf => {
                                            if (rf) {
                                                rf.volume = 0.1;
                                            }
                                        }}>
                                    <source src="https://portal.asstra.com/video/AsstrA - Track & Trace PL.mp4"/>
                                </Player>
                            }

                            {i18n.language.toLowerCase() === 'pl' &&
                                <Player preload={'metadata'}
                                        ref={rf => {
                                            if (rf) {
                                                rf.volume = 0.1;
                                            }
                                        }}>
                                    <source src="https://portal.asstra.com/video/AsstrA - Track & Trace PL.mp4"/>
                                </Player>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ProfileHelp;
